import { graphql } from 'gatsby';
import Link from '@components/Link';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect } from 'react';

import Button from '@atoms/Button/Button';
import Section from '@atoms/Section/Section';
import Footer from '@components/Footer/Footer';
// Generic
import MarkdownText from '@atoms/MarkdownText/MarkdownText';
// Modules
import Header from '@components/Header/Header';
import HeaderNav from '@components/HeaderNavV2/HeaderNav';
import Layout from '@components/layout';
import Seo from '@components/seo';
import { withLocale, withTracking } from '@hocs';
import { useFetchMarkdownContent, useTracking } from '@hooks';


export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        aepsyCms {
            contentDE: markdownContents(
                locale: "de-CH"
                filters: { key: { eq: "PRIVACY" } }
            ) {
                content
            }
            contentEN: markdownContents(
                locale: "en"
                filters: { key: { eq: "PRIVACY" } }
            ) {
                content
            }
            contentFR: markdownContents(
                locale: "fr-CH"
                filters: { key: { eq: "PRIVACY" } }
            ) {
                content
            }
            contentIT: markdownContents(
                locale: "it"
                filters: { key: { eq: "PRIVACY" } }
            ) {
                content
            }
        }
    }
`;


const PrivacyPage = ({ data }) => {
    const { t } = useTranslation();

    const { content } = useFetchMarkdownContent({ query: data });
    const { trackPageView } = useTracking();
    useEffect(() => {
        trackPageView();
    }, []);

    return (
        <Layout>
            <Seo title={t('data.privacy.title')} />
            <div className="global_theme-green">
                <HeaderNav theme="white" />
                <Section theme="dark">
                    <Header
                        theme="dark"
                        title={t('data.privacy.title')}
                        align="center"
                        spacing="s"
                        size="thin"
                    />
                </Section>

                <Section theme="light" spacing="m" container="large">
                    <MarkdownText content={content} />

                    <Section spacing="m">
                        <Link to="/">
                            <Button label={t('button.back')} />
                        </Link>
                    </Section>
                </Section>

                <Footer />
            </div>
        </Layout>
    );
};

export default withTracking(withLocale(PrivacyPage));
